import { render, staticRenderFns } from "./CalendarReservation.vue?vue&type=template&id=72cb5904&"
import script from "./CalendarReservation.vue?vue&type=script&lang=js&"
export * from "./CalendarReservation.vue?vue&type=script&lang=js&"
import style0 from "./CalendarReservation.vue?vue&type=style&index=0&id=72cb5904&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports